import React from 'react';
import Layout from "../components/layout"
import GeneralSection from "../components/newComponents/generalSection";
import Seo from "../components/seo";
//import WhistleBlowingPolicy from '../components/newComponents/whislteBlowingForm';

const WhistleBlowingPolicyPage = () => (
  <Layout>
    <Seo title="Whistle blowing policy" />
    <div className="main">
      <GeneralSection className="section-inside" style={{paddingBottom: 0}}>
        <header className="section__head">
          <h1 className="section__title title h2 text-gray">Whistle blowing policy</h1>
        </header>
      </GeneralSection>
      <GeneralSection>
        <div className="prose max-w-none mb-8 body-content-rich-text-bullets">This form is for any individual working with or for Genesis Analytics (including staff, suppliers, sub-contractors, partners, vendors and clients) who wishes to raise an issue about wrongdoing in the firm. Staff can refer to the Whistle Blowing Policy on the Resource Site for more details regarding this.</div>
      </GeneralSection>
      <GeneralSection>
        <iframe src="https://flowcommunications.formstack.com/forms/whistle_blowing_form" title="Whistle Blowing Form" width="100%" height="1920"></iframe>
      </GeneralSection>
    </div>
  </Layout>
);

export default WhistleBlowingPolicyPage;